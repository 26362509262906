@charset "utf-8";
/**
*
* -----------------------------------------------------------------------------
*
* Template : Tekhub - Multipurpose Technology Startup HTML Template
* Author : rs-theme
* Author URI : http://www.rstheme.com/
*
* -----------------------------------------------------------------------------
*
**/
/* Table Of Content
---------------------------------------------------------
    01. General CSS
    02. Common CSS
    03. Header CSS
    04. Banner Section Css
    05. Breadcrumbs Section Css
    06. Features Section Css
    07. Work Flow Section Css
    08. Services Section Css
    09. Machine Learning Section Css
    10. QA & Testing Section Css
    11. Language Processing Section Css
    12. About Section Css
    13. Team Section Css
    14. Testimonial Section Css
    15. Blog Section Css
    16. Call to Action Section Css
    17. Conatct Section Css
    18. Footer Section Css
    19. Search Modal Css
    20. Header Cart Modal Css
    21. Preloader CSS
--------------------------------------------------------*/
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cairo/v10/SLXLc1nY6HkvalqKbI6L59M.ttf) format('truetype');
}
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cairo/v10/SLXGc1nY6HkvalIhTp4.ttf) format('truetype');
}
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cairo/v10/SLXLc1nY6Hkvalr-ao6L59M.ttf) format('truetype');
}
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cairo/v10/SLXLc1nY6Hkvalqaa46L59M.ttf) format('truetype');
}
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/cairo/v10/SLXLc1nY6HkvalqiaY6L59M.ttf) format('truetype');
}
/* -----------------------------------
    01. General CSS
-------------------------------------*/
html,
body {
  font-size: 16px;
  color: #555555;
  font-family: "Cairo", sans-serif;
  vertical-align: baseline;
  font-weight: 400;
  overflow-x: hidden;
  line-height: 29px;
}
body {
  padding: 0 !important;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  margin: 0 0 26px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cairo", sans-serif;
  margin: 0 0 26px;
}
h1 {
  font-size: 60px;
  line-height: 75px;
  font-weight: 700;
  color: #003b40;
}
h2 {
  font-size: 42px;
  line-height: 55px;
  font-weight: 600;
  color: #003b40;
}
h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #3b4052;
}
h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #3b4052;
}
h5 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #3b4052;
}
h6 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #3b4052;
}
a {
  color: #e2b500;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  outline: none !important;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  color: #3b4052;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
/* -----------------------------------
    02. Common CSS
-------------------------------------*/
.clear {
  clear: both;
}
.lh1 {
  line-height: 1 !important;
}
.z-index-0 {
  z-index: 0 !important;
}
.no-border {
  border: none !important;
}
.relative {
  position: relative !important;
}
.hide-over {
  overflow: hidden;
}
.capitalize {
  text-transform: capitalize !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.container-fluid {
  padding: 0 60px;
}
.radius-0 {
  border-radius: unset !important;
}
.semi-bold {
  font-weight: 600;
}
.light-font {
  font-weight: 300;
}
.secondary-color {
  color: #458dff;
}
.secondary-bg {
  background-color: #458dff;
}
.primary-color {
  color: #e2b500;
}
.primary-bg {
  background-color: #e2b500;
}
.white-color {
  color: #ffffff !important;
}
.white-bg {
  background-color: #ffffff !important;
}
.red-color {
  color: #e8505b !important;
}
.red-bg {
  background-color: #e8505b !important;
}
.title-color {
  color: #3b4052;
}
.gray-bg {
  background: #f5feff;
}
.bg-fixed {
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
}
.y-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.y-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
::-moz-selection {
  background: #e2b500;
  text-shadow: none;
  color: #ffffff;
}
::selection {
  background: #e2b500;
  text-shadow: none;
  color: #ffffff;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin: 0;
}
.mfp-figure .mfp-close {
  top: 0;
  padding: 0;
  right: 0;
  opacity: 1;
  color: #e2b500;
}
.mfp-figure .mfp-counter {
  top: 0;
  right: 0;
}
.mfp-figure:after {
  display: none;
}
.boxshadow {
  box-shadow: none;
}
.img-shadow {
  -webkit-box-shadow: 5px 12px 26px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 5px 12px 26px 0px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 5px 12px 26px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 12px 26px 0px rgba(0, 0, 0, 0.2);
}
.grid-shadow {
  -webkit-box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.06);
  -o-box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.06);
}
.sec-title .sub-title {
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 3px;
}
.sec-title .desc {
  font-size: 16px;
}
.sec-title .desc.mw-720 {
  max-width: 720px;
  margin: 0 auto;
}
.sec-title .desc2 {
  font-size: 18px;
  color: #3b4052;
}
.readon {
  padding: 13px 36px;
  font-weight: 500;
  background: #458dff;
  color: #ffffff;
  cursor: pointer;
  border: 2px solid #458dff;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s ease 0s;
  border-radius: 3px;
  outline: none;
}
.readon:hover {
  background: transparent;
  border-color: #003b40;
  color: #3b4052;
}
.readon.active {
  background: transparent;
  border-color: #003b40;
  color: #3b4052;
}
.readon.active:hover {
  background: #458dff;
  border-color: #458dff;
  color: #ffffff;
}
.readon:focus {
  outline: none;
}
.readon.sl-btn {
  min-width: 176px;
  text-align: center !important;
  transition: all 0.3s ease 0s !important;
}
.readon2 {
  display: inline-block;
  color: #3b4052;
  position: relative;
  font-weight: 600;
  padding-right: 35px;
}
.readon2:after {
  position: absolute;
  content: "\f120";
  font-family: Flaticon;
  right: 5px;
  font-weight: normal;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  color: #3b4052;
}
.readon2:hover {
  color: #e2b500;
}
.readon2:hover:after {
  right: 0;
  color: #e2b500;
}
.readon3 {
  position: relative;
  font-weight: 600;
  color: #003b40;
  padding-right: 25px;
}
.readon3:after {
  font-family: Flaticon;
  content: "\f120";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
.readon3:hover {
  padding-right: 30px;
}
.no-gutter {
  margin: 0;
}
.no-gutter [class*="col-"] {
  padding: 0;
}
#scrollUp {
  text-align: center;
  bottom: 40px;
  cursor: pointer;
  position: fixed;
  right: 40px;
  z-index: 999;
  background: #e2b500;
  color: #ffffff;
  border-radius: 50%;
  width: 45px;
  line-height: 40px;
  font-size: 24px;
  height: 45px;
  transition: all 0.3s ease 0s;
}
#scrollUp:hover {
  background: #d3a900;
}
.listing-style.space-50 li {
  margin-bottom: 32px;
}
.listing-style li {
  padding-left: 40px;
  position: relative;
  font-weight: 600;
  color: #3b4052;
  margin-bottom: 20px;
}
.listing-style li:after,
.listing-style li:before {
  position: absolute;
  content: "";
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}
.listing-style li:after {
  top: 7px;
  background: #d6fbff;
}
.listing-style li:before {
  top: 3px;
  left: 3px;
  border: 1px dashed #e2b500;
  animation: spine 10s linear infinite;
  -webkit-animation: spine 10s linear infinite;
}
.listing-style li:last-child {
  margin: 0;
}
.pagenav {
  padding: 11px 30px;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: inline-block;
}
.pagenav li {
  display: inline;
  margin-right: 16px;
  padding-right: 26px;
  position: relative;
}
.pagenav li a {
  color: #555555;
  font-weight: 600;
}
.pagenav li a i {
  color: #003b40;
  transition: all 0.3s ease 0s;
}
.pagenav li a i:before {
  font-size: 17px;
}
.pagenav li a:hover {
  color: #e2b500;
}
.pagenav li a:hover i {
  color: #e2b500;
}
.pagenav li.active a {
  color: #458dff;
}
.pagenav li.prev a,
.pagenav li.next a {
  font-weight: 400;
}
.pagenav li:after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #d6fbff;
}
.pagenav li:last-child {
  margin: 0;
  padding: 0;
}
.pagenav li:last-child:after {
  position: unset;
  content: unset;
}
/* Owl Carousel Nav */
.rs-carousel {
  padding-top: 0;
}
.rs-carousel .owl-nav [class*="owl-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #e2b500;
  color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  opacity: 0 !important;
  visibility: hidden !important;
  transition: all 0.3s ease 0s;
  outline: none;
}
.rs-carousel .owl-nav [class*="owl-"]:after {
  content: "\f128";
  font-family: Flaticon;
  font-size: 15px;
}
.rs-carousel .owl-nav [class*="owl-"] i {
  font-size: 0;
  position: relative;
}
.rs-carousel .owl-nav [class*="owl-"].owl-prev {
  left: 15px;
}
.rs-carousel .owl-nav [class*="owl-"].owl-next {
  right: 15px;
}
.rs-carousel .owl-nav [class*="owl-"].owl-next:after {
  content: "\f120";
}
.rs-carousel .owl-nav [class*="owl-"]:hover {
  background-color: #458dff;
}
.rs-carousel .owl-dots {
  transition: all 0.3s ease 0s;
  text-align: center;
  margin-top: 25px;
}
.rs-carousel .owl-dots .owl-dot {
  width: 15px;
  height: 5px;
  border-radius: 3px;
  background-color: transparent;
  margin: 0 5px;
  transition: all 0.3s ease 0s;
  background: #e2b500;
  outline: none;
  display: inline-block;
}
.rs-carousel .owl-dots .owl-dot.active {
  width: 30px;
}
.rs-carousel:hover [class*="owl-"] {
  opacity: 1 !important;
  visibility: visible !important;
}
.rs-carousel:hover [class*="owl-"] .owl-prev {
  left: -50px;
}
.rs-carousel:hover [class*="owl-"] .owl-next {
  right: -50px;
}
/* Background Css */
.bg1 {
  background: url(src/images/background/bg1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}
.bg2 {
  background: url(src/images/background/bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg3 {
  background: url(src/images/background/bg3.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg4 {
  background: url(src/images/background/bg4.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg5 {
  background: url(src/images/background/bg5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}
/*-----------------------------------------------
    03. Header CSS
-----------------------------------------------*/
.full-width-header .rs-header {
  position: relative;
  /*------Transparent Header------*/
}
.full-width-header .rs-header.transparent-header {
  position: absolute;
  content: "";
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.full-width-header .rs-header.transparent-header .menu-area .main-menu .rs-menu {
  padding-right: 65px;
}
.full-width-header .rs-header.transparent-header .menu-area .main-menu .rs-menu ul.sub-menu {
  top: 100%;
}
.full-width-header .rs-header.transparent-header .menu-area .main-menu .rs-menu ul.sub-menu ul.sub-menu {
  top: 0;
}
.full-width-header .rs-header.transparent-header .menu-area .main-menu .rs-menu .head-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}
.full-width-header .rs-header.transparent-header .menu-area .main-menu .rs-menu .head-btn a {
  display: inline-block;
  padding: 12px 33px;
  color: #ffffff;
  background: #e2b500;
  border-radius: 30px;
  font-size: 15px;
  height: unset;
  line-height: unset;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.2);
}
.full-width-header .rs-header.transparent-header .menu-area .main-menu .rs-menu .head-btn a:hover {
  background: #d3a900;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .logo-area a.logo img {
  transition: all 0.3s ease 0s !important;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .logo-area a.logo img.sticky-logo {
  display: none;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .main-menu .rs-menu .nav-menu > li > a {
  color: #ffffff;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .main-menu .rs-menu .nav-menu > li > a:hover {
  color: #e2b500 !important;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .main-menu .rs-menu .nav-menu > li .sub-menu li a:hover {
  color: #e2b500 !important;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .main-menu .rs-menu .nav-menu > li .sub-menu li.active a {
  color: #e2b500 !important;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .main-menu .rs-menu .nav-menu > li.current-menu-item > a {
  color: #e2b500 !important;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .expand-btn-inner li > a {
  color: #ffffff;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .expand-btn-inner li > a:hover {
  color: #e2b500;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .expand-btn-inner .woocommerce-mini-cart .icon-cart i:hover {
  color: #e2b500 !important;
  border-color: #e2b500 !important;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .expand-btn-inner .woocommerce-mini-cart .product-info a:hover {
  color: #e2b500 !important;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .expand-btn-inner .woocommerce-mini-cart .cart-btn .crt-btn {
  border-color: #e2b500;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area .expand-btn-inner .woocommerce-mini-cart .cart-btn .crt-btn:hover {
  background: #e2b500;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area.sticky .logo-area a.logo img.normal-logo {
  display: none;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area.sticky .logo-area a.logo img.sticky-logo {
  display: inherit;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area.sticky .main-menu .rs-menu .nav-menu > li > a {
  color: #3b4052;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area.sticky .main-menu .rs-menu .nav-menu > li > a:hover {
  color: #e2b500;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area.sticky .main-menu .rs-menu .nav-menu > li.current-menu-item > a {
  color: #e2b500;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area.sticky .expand-btn-inner li > a {
  color: #3b4052;
}
.full-width-header .rs-header.transparent-header.white-menu .menu-area.sticky .expand-btn-inner li > a:hover {
  color: #e2b500;
}
.full-width-header .rs-header .rs-menu-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.full-width-header .rs-header .rs-menu-area .expand-btn-inner li {
  display: inline-block;
  margin-right: 21px;
}
.full-width-header .rs-header .rs-menu-area .expand-btn-inner li > a {
  color: #3b4052;
  display: inline-block;
  cursor: pointer;
}
.full-width-header .rs-header .rs-menu-area .expand-btn-inner li > a i:before {
  font-size: 20px;
  font-weight: 700;
}
.full-width-header .rs-header .rs-menu-area .expand-btn-inner li > a:hover {
  color: #458dff;
}
.full-width-header .rs-header .rs-menu-area .expand-btn-inner li:last-child {
  margin-right: 0;
}
/*-----------------------------------------------
    04. Banner Section Css
-----------------------------------------------*/
.rs-banner {
  position: relative;
}
.rs-banner.home-banner .tp-bgimg {
  background-attachment: fixed;
}
.rs-banner .banner-desc {
  color: #3b4052;
  font-size: 18px;
}
/*-----------------------------------------------
    05. Breadcrumbs Section Css
-----------------------------------------------*/
.rs-breadcrumbs .breadcrumbs-image {
  position: relative;
  overflow: hidden;
}
.rs-breadcrumbs .breadcrumbs-image img {
  width: 100%;
}
.rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner {
  width: 100%;
  position: absolute;
  top: 56%;
  left: 0;
  transform: translateY(-50%);
}
.rs-breadcrumbs .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-title {
  width: 100%;
  font-size: 36px;
  line-height: 56px;
  font-weight: 600;
  color: #3b4052;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner {
  top: unset;
  transform: unset;
  bottom: 65px;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-title {
  color: #ffffff;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta {
  align-items: center;
  display: flex;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li {
  margin-right: 60px;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .postedby {
  display: flex;
  align-items: center;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .postedby .avatar {
  margin-right: 20px;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .postedby .info {
  color: #ffffff;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .postedby .info .name {
  margin: 0;
  color: #ffffff;
  font-weight: 600;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .postedby .info .designation {
  font-weight: 300;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .title {
  color: #ffffff;
  margin: 0;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li a {
  font-weight: 300;
  color: #ffffff;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li a:hover {
  color: #e2b500;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li span {
  font-weight: 300;
  color: #ffffff;
}
.rs-breadcrumbs.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li:last-child {
  margin: 0;
}
.rs-breadcrumbs2 .breadcrumbs-image {
  position: relative;
  overflow: hidden;
}
.rs-breadcrumbs2 .breadcrumbs-image img {
  width: 100%;
}
.rs-breadcrumbs2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-title {
  width: 100%;
  font-size: 36px;
  line-height: 56px;
  font-weight: 600;
  color: #3b4052;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-text .breadcrumbs-title {
  color: #ffffff;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta {
  align-items: center;
  display: flex;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li {
  margin-right: 60px;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .postedby {
  display: flex;
  align-items: center;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .postedby .avatar {
  margin-right: 20px;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .postedby .info {
  color: #ffffff;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .postedby .info .name {
  margin: 0;
  color: #ffffff;
  font-weight: 600;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .postedby .info .designation {
  font-weight: 300;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li .title {
  color: #ffffff;
  margin: 0;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li a {
  font-weight: 300;
  color: #ffffff;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li a:hover {
  color: #e2b500;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li span {
  font-weight: 300;
  color: #ffffff;
}
.rs-breadcrumbs2.style2 .breadcrumbs-image .breadcrumbs-inner .breadcrumbs-meta li:last-child {
  margin: 0;
}
.blog-details-bg {
  background: url(src/images/breadcrumbs/2.jpg);
  background-size: cover;
  background-position: center;
}
/*-----------------------------------------------
    06. Features Section Css
-----------------------------------------------*/
.rs-features .circle-dot {
  width: 35px;
  height: 35px;
  position: absolute;
  left: 4px;
  top: 6px;
  border-radius: 100%;
  background: #ffffff;
  border: 6px solid #e2b500;
  animation: y-anim1 3s alternate infinite;
  -webkit-animation: y-anim1 3s alternate infinite;
}
.rs-features .circle-dot.right {
  left: unset;
  top: unset;
  width: 45px;
  height: 45px;
  border: 8px solid #e2b500;
  right: 0;
  bottom: 70px;
  animation: x-anim1 4s alternate infinite;
  -webkit-animation: x-anim1 4s alternate infinite;
}
.rs-features .feature-img {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  text-align: center;
}
.rs-features .feature-img .main-image {
  position: relative;
  z-index: 1;
}
.rs-features .feature-img .shape {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}
.rs-features .feature-img .shape img {
  animation: pendulum 1s alternate infinite;
  -webkit-animation: pendulum 1s alternate infinite;
}
.rs-features .feature-img .circle-ring {
  width: 205px;
  height: 205px;
  border-radius: 100%;
  position: absolute;
  left: -50px;
  opacity: 0.5;
  top: -50px;
  border: 2px dashed #e2b500;
  z-index: 0;
  animation: spine 30s linear infinite;
  -webkit-animation: spine 30s linear infinite;
}
.rs-features .feature-img .circle-ring.right {
  width: 170px;
  height: 170px;
  left: unset;
  top: 74px;
  right: -48px;
  animation: spine2 30s linear infinite;
  -webkit-animation: spine2 30s linear infinite;
}
.rs-features .feature-wrap .icon-part {
  position: relative;
  display: inline-block;
  padding: 4px 21px 0 0;
}
.rs-features .feature-wrap .icon-part img {
  z-index: 1;
  position: relative;
}
.rs-features .feature-wrap .icon-part .circle-anim {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: transparent;
  border-radius: 100%;
  right: 0;
  top: 0;
}
.rs-features .feature-wrap .icon-part .circle-anim:after {
  position: absolute;
  content: "";
  right: -13px;
  top: -6px;
  width: 100%;
  height: 100%;
  border: 1px dashed #e2b500;
  border-radius: 100%;
  z-index: 0;
  transition: all 0.3s ease 0s;
  animation: spine 10s linear infinite;
  -webkit-animation: spine 10s linear infinite;
}
.rs-features .feature-wrap .icon-part .circle-anim:before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #d6fbff;
  border-radius: 100%;
  z-index: 1;
  transition: all 0.3s ease 0s;
}
.rs-features .feature-wrap:hover .icon-part .circle-anim:after {
  opacity: 0;
  top: 0;
  right: 0;
}
/*-----------------------------------------------
    07. Work Flow Section Css
-----------------------------------------------*/
.rs-work-flow .flow-wrap {
  display: flex;
  align-items: center;
}
.rs-work-flow .flow-wrap .flow-common.flow-left {
  width: 15%;
  text-align: left;
  border-color: #ffdb49;
  border-width: 2px;
  border-style: dashed dashed dashed none;
  border-radius: 0 30px 30px 0;
  position: relative;
}
.rs-work-flow .flow-wrap .flow-common.flow-left .content {
  text-align: center;
  background: #fef8dc;
  padding: 102px 0;
  border-radius: 0 30px 30px 0;
  margin: 30px 30px 30px 0;
}
.rs-work-flow .flow-wrap .flow-common.flow-left .content .title {
  font-size: 22px;
}
.rs-work-flow .flow-wrap .flow-common.flow-left:after {
  position: absolute;
  content: "";
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #ffffff;
  border: 4px solid #e2b500;
  transition: all 0.3s ease 0s;
}
.rs-work-flow .flow-wrap .flow-common.flow-left:hover:after {
  background: #e2b500;
}
.rs-work-flow .flow-wrap .flow-common.flow-icon-left {
  width: 15%;
  position: relative;
}
.rs-work-flow .flow-wrap .flow-common.flow-icon-left .icon-wrap {
  width: 205px;
  height: 205px;
  line-height: 205px;
  border-radius: 100%;
  text-align: center;
  margin: 0 auto;
  box-shadow: -1px 5px 11px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}
.rs-work-flow .flow-wrap .flow-common.flow-icon-left:after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, #e2b500 61%, transparent 0%);
  background-position: bottom;
  background-size: 10px 13px;
  background-repeat: repeat-x;
  z-index: -1;
}
.rs-work-flow .flow-wrap .flow-common.flow-center {
  width: 40%;
  position: relative;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .empty-space-left {
  height: 100%;
  width: 70px;
  position: absolute;
  z-index: -1;
  border-color: #ffdb49;
  border-width: 2px;
  border-style: dashed none dashed dashed;
  border-radius: 30px 0 0 30px;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .empty-space-left:after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, #e2b500 61%, transparent 0%);
  background-position: bottom;
  background-size: 10px 13px;
  background-repeat: repeat-x;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .empty-space-right {
  height: 100%;
  width: 70px;
  position: absolute;
  right: 0;
  z-index: -1;
  border-color: #ffdb49;
  border-width: 2px;
  border-style: dashed dashed dashed none;
  border-radius: 0 30px 30px 0;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .empty-space-right:after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, #e2b500 61%, transparent 0%);
  background-position: bottom;
  background-size: 10px 13px;
  background-repeat: repeat-x;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-left {
  padding-left: 70px;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-left li {
  font-weight: 600;
  color: #3b4052;
  padding-left: 35px;
  position: relative;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-left li:nth-child(1) {
  margin-top: -13px;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-left li:nth-child(2) {
  margin-top: 257px;
  margin-bottom: 257px;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-left li:nth-child(3) {
  margin-bottom: -13px;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-left li:after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  background: #ffffff;
  border: 4px solid #e2b500;
  transition: all 0.3s ease 0s;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-left li:hover:after {
  background: #e2b500;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-right {
  padding-right: 70px;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-right li {
  font-weight: 600;
  color: #3b4052;
  padding-right: 35px;
  position: relative;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-right li:nth-child(1) {
  margin-top: -13px;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-right li:nth-child(2) {
  margin-top: 257px;
  margin-bottom: 257px;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-right li:nth-child(3) {
  margin-bottom: -13px;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-right li:after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  background: #ffffff;
  border: 4px solid #e2b500;
  transition: all 0.3s ease 0s;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .flowlist-right li:hover:after {
  background: #e2b500;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .center-line {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 500px;
  background-image: linear-gradient(to top, #e2b500 61%, transparent 0%);
  background-position: bottom;
  background-size: 10px 13px;
  background-repeat: repeat-y;
}
.rs-work-flow .flow-wrap .flow-common.flow-center .center-line:after {
  position: absolute;
  content: "";
  left: -14px;
  top: -20px;
  border: 4px solid #e2b500;
  background: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  animation: up-down 10s alternate infinite;
  -webkit-animation: up-down 10s alternate infinite;
}
.rs-work-flow .flow-wrap .flow-common.flow-icon-right {
  width: 15%;
  position: relative;
}
.rs-work-flow .flow-wrap .flow-common.flow-icon-right .icon-wrap {
  width: 205px;
  height: 205px;
  line-height: 205px;
  border-radius: 100%;
  text-align: center;
  margin: 0 auto;
  box-shadow: -1px 5px 11px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}
.rs-work-flow .flow-wrap .flow-common.flow-icon-right:after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, #e2b500 61%, transparent 0%);
  background-position: bottom;
  background-size: 10px 13px;
  background-repeat: repeat-x;
  z-index: -1;
}
.rs-work-flow .flow-wrap .flow-common.flow-right {
  width: 15%;
  text-align: left;
  border-color: #ffdb49;
  border-width: 2px;
  border-style: dashed none dashed dashed;
  border-radius: 30px 0 0 30px;
  transition: all 0.3s ease 0s;
  position: relative;
}
.rs-work-flow .flow-wrap .flow-common.flow-right .content {
  text-align: center;
  background: #fef8dc;
  padding: 102px 0;
  border-radius: 30px 0 0 30px;
  margin: 30px 0 30px 30px;
}
.rs-work-flow .flow-wrap .flow-common.flow-right .content .title {
  font-size: 22px;
}
.rs-work-flow .flow-wrap .flow-common.flow-right:after {
  position: absolute;
  content: "";
  left: -11px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #ffffff;
  border: 4px solid #e2b500;
  transition: all 0.3s ease 0s;
}
.rs-work-flow .flow-wrap .flow-common.flow-right:hover:after {
  background: #e2b500;
}
/*-----------------------------------------------
    08. Services Section Css
-----------------------------------------------*/
.rs-services .service-wrap .icon-part {
  position: relative;
  display: inline-block;
  padding: 11px 20px 0 0;
}
.rs-services .service-wrap .icon-part img {
  z-index: 1;
  position: relative;
}
.rs-services .service-wrap .icon-part .circle-anim {
  position: absolute;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: transparent;
  border-radius: 100%;
  right: 0;
  top: 0;
}
.rs-services .service-wrap .icon-part .circle-anim:after {
  position: absolute;
  content: "";
  right: -13px;
  top: -10px;
  width: 100%;
  height: 100%;
  border: 1px dashed #e2b500;
  border-radius: 100%;
  z-index: 0;
  transition: all 0.3s ease 0s;
  animation: spine 10s linear infinite;
  -webkit-animation: spine 10s linear infinite;
}
.rs-services .service-wrap .icon-part .circle-anim:before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #d6fbff;
  border-radius: 100%;
  z-index: 1;
  transition: all 0.3s ease 0s;
}
.rs-services .service-wrap .content-part .title a {
  color: #ffffff;
}
.rs-services .service-wrap .content-part .title a:hover {
  color: #e2b500;
}
.rs-services .service-wrap .content-part .desc {
  color: #d6d6d6;
}
.rs-services .service-wrap:hover .icon-part .circle-anim:after {
  opacity: 0;
  top: 0;
  right: 0;
}
.rs-services.style2 .service-wrap .content-part .title a {
  color: #003b40;
}
.rs-services.style2 .service-wrap .content-part .title a:hover {
  color: #e2b500;
}
.rs-services.style2 .service-wrap .content-part .desc {
  color: #555555;
}
/*-----------------------------------------------
    09. Machine Learning Section Css
-----------------------------------------------*/
.rs-machine-learning .image-part {
  position: relative;
}
.rs-machine-learning .image-part .spinner-holder {
  width: 431px;
  height: 431px;
  position: absolute;
  left: 46%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.rs-machine-learning .image-part .spinner-holder img {
  position: absolute;
  left: -80px;
  top: -30px;
  animation: spine 30s linear infinite;
}
.rs-machine-learning .image-part .spinner-holder img:nth-child(2) {
  left: unset;
  right: 0;
  top: unset;
  bottom: 0;
  animation: spine2 30s linear infinite;
}
/*-----------------------------------------------
    10. QA & Testing Section Css
-----------------------------------------------*/
.rs-qa-testing .content-part .some-text {
  background: #ebfdff;
  padding: 30px 36px 25px;
  font-weight: 600;
  line-height: 30px;
}
/*-----------------------------------------------
    11. Language Processing Section Css
-----------------------------------------------*/
.rs-language-processing .image-part {
  position: relative;
}
.rs-language-processing .image-part .spinner-holder {
  width: 431px;
  height: 431px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.rs-language-processing .image-part .spinner-holder img {
  position: absolute;
  left: -10px;
  top: 0;
  animation: spine 30s linear infinite;
}
.rs-language-processing .image-part .spinner-holder img:nth-child(2) {
  left: unset;
  right: -45px;
  top: unset;
  bottom: 0;
  animation: spine2 30s linear infinite;
}
/*-----------------------------------------------
    12. About Section Css
-----------------------------------------------*/
.rs-about .social-wrap {
  width: 650px;
  height: 650px;
  position: relative;
  border-radius: 100%;
  margin: 0 auto;
}
.rs-about .social-wrap .center-icon {
  width: 300px;
  height: 300px;
  line-height: 300px;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0 5px 19px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  transition: all 0.3s ease 0s;
}
.rs-about .social-wrap .center-icon i {
  color: #e2b500;
  transition: all 0.3s ease 0s;
}
.rs-about .social-wrap .center-icon i:before {
  font-size: 200px;
}
.rs-about .social-wrap .social-links li {
  position: absolute;
}
.rs-about .social-wrap .social-links li a {
  display: inline-block;
  box-shadow: 0 5px 19px 0 rgba(0, 0, 0, 0.08);
  text-align: center;
  background: #ffffff;
  border-radius: 100%;
}
.rs-about .social-wrap .social-links li:nth-child(1) {
  right: 110px;
}
.rs-about .social-wrap .social-links li:nth-child(1) a {
  width: 85px;
  height: 85px;
  line-height: 85px;
  font-size: 35px;
  color: #374fa0;
}
.rs-about .social-wrap .social-links li:nth-child(1) a:hover {
  background: #374fa0;
  color: #ffffff;
}
.rs-about .social-wrap .social-links li:nth-child(2) {
  right: -45px;
  top: 200px;
}
.rs-about .social-wrap .social-links li:nth-child(2) a {
  width: 115px;
  height: 115px;
  line-height: 115px;
  font-size: 40px;
  color: #ffc10d;
}
.rs-about .social-wrap .social-links li:nth-child(2) a:hover {
  background: #ffc10d;
  color: #ffffff;
}
.rs-about .social-wrap .social-links li:nth-child(3) {
  right: 38px;
  bottom: 72px;
}
.rs-about .social-wrap .social-links li:nth-child(3) a {
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 50px;
  color: #e0e21c;
}
.rs-about .social-wrap .social-links li:nth-child(3) a:hover {
  background: #e0e21c;
  color: #ffffff;
}
.rs-about .social-wrap .social-links li:nth-child(4) {
  bottom: -37px;
  left: 305px;
}
.rs-about .social-wrap .social-links li:nth-child(4) a {
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 38px;
  color: #62ba46;
}
.rs-about .social-wrap .social-links li:nth-child(4) a:hover {
  background: #62ba46;
  color: #ffffff;
}
.rs-about .social-wrap .social-links li:nth-child(5) {
  bottom: 66px;
  left: 9px;
}
.rs-about .social-wrap .social-links li:nth-child(5) a {
  width: 120px;
  height: 120px;
  line-height: 120px;
  font-size: 65px;
  color: #399bd4;
}
.rs-about .social-wrap .social-links li:nth-child(5) a:hover {
  background: #399bd4;
  color: #ffffff;
}
.rs-about .social-wrap .social-links li:nth-child(6) {
  left: -45px;
  top: 150px;
}
.rs-about .social-wrap .social-links li:nth-child(6) a {
  width: 130px;
  height: 130px;
  line-height: 130px;
  font-size: 60px;
  color: #ff0000;
}
.rs-about .social-wrap .social-links li:nth-child(6) a:hover {
  background: #ff0000;
  color: #ffffff;
}
.rs-about .social-wrap .social-links li:nth-child(7) {
  left: 165px;
  top: -30px;
}
.rs-about .social-wrap .social-links li:nth-child(7) a {
  width: 95px;
  height: 95px;
  line-height: 95px;
  font-size: 40px;
  color: #db7627;
}
.rs-about .social-wrap .social-links li:nth-child(7) a:hover {
  background: #db7627;
  color: #ffffff;
}
.rs-about .social-wrap:after {
  position: absolute;
  content: "";
  animation: spine 60s linear infinite;
  -webkit-animation: spine 60s linear infinite;
  border: 2px dashed #e2b500;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.rs-about .social-wrap:hover .center-icon {
  background: #e2b500;
}
.rs-about .social-wrap:hover .center-icon i {
  color: #ffffff;
}
.rs-about.style2 .image-wrap {
  max-width: 643px;
  height: 643px;
  margin: 0 0 2px auto;
  position: relative;
}
.rs-about.style2 .image-wrap .robot {
  position: absolute;
  bottom: 0;
  right: 125px;
}
.rs-about.style2 .image-wrap .robot .head {
  position: absolute;
  top: -20px;
  right: 125px;
  animation-duration: 5s;
  -webkit-animation-duration: 5s;
}
.rs-about.style2 .image-wrap .fly {
  position: absolute;
}
.rs-about.style2 .image-wrap .fly.ly1 {
  top: 0;
  right: 0;
}
.rs-about.style2 .image-wrap .fly.ly2 {
  top: 45%;
  left: 0;
}
.rs-about.style2 .image-wrap .fly.ly3 {
  bottom: 20%;
  right: 0;
}
.rs-about.style2 .image-wrap .fly.ly4 {
  top: 10px;
  left: 65px;
}
.rs-about.style2 .image-wrap .fly.spins-wrap1 {
  width: 117px;
  height: 88px;
  top: 265px;
  right: 120px;
}
.rs-about.style2 .image-wrap .fly.spins-wrap1 img {
  position: absolute;
}
.rs-about.style2 .image-wrap .fly.spins-wrap1 img:nth-child(1) {
  left: 0;
  bottom: 0;
}
.rs-about.style2 .image-wrap .fly.spins-wrap1 img:nth-child(2) {
  right: 0;
  bottom: 0;
}
.rs-about.style2 .image-wrap .fly.spins-wrap1 img:nth-child(3) {
  right: 13px;
  top: 0;
}
.rs-about.style2 .image-wrap .fly.spins-wrap2 {
  width: 112px;
  height: 111px;
  top: 143px;
  left: 45px;
}
.rs-about.style2 .image-wrap .fly.spins-wrap2 img {
  position: absolute;
}
.rs-about.style2 .image-wrap .fly.spins-wrap2 img:nth-child(1) {
  right: 0;
  bottom: 0;
}
.rs-about.style2 .image-wrap .fly.spins-wrap2 img:nth-child(2) {
  left: 6px;
  top: 0;
}
.rs-about.style2 .image-wrap .fly.spins-wrap2 img:nth-child(3) {
  left: 0;
  bottom: 16px;
}
/*-----------------------------------------------
    13. Team Section Css
-----------------------------------------------*/
.rs-team .item .team-wrap {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.rs-team .item .team-wrap .content-part {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease 0s;
}
.rs-team .item .team-wrap .content-part .name {
  font-size: 22px;
  margin-bottom: 0;
}
.rs-team .item .team-wrap .content-part .name a {
  color: #ffffff;
}
.rs-team .item .team-wrap .content-part .name a:hover {
  color: #e2b500;
}
.rs-team .item .team-wrap .content-part .designation {
  color: #ffffff;
  font-weight: 300;
  margin-top: -3px;
}
.rs-team .item .team-wrap .content-part .social-links {
  margin-top: 17px;
}
.rs-team .item .team-wrap .content-part .social-links li {
  display: inline;
  margin-right: 10px;
}
.rs-team .item .team-wrap .content-part .social-links li a {
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 30px;
  border-radius: 100%;
  background: #ffffff;
  color: #e2b500;
  font-size: 13px;
}
.rs-team .item .team-wrap .content-part .social-links li a:hover {
  color: #3b4052;
}
.rs-team .item .team-wrap .content-part .social-links li:last-child {
  margin: 0;
}
.rs-team .item .team-wrap:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(24, 53, 169, 0.8);
  transition: all 0.3s ease 0s;
  opacity: 0;
}
.rs-team .item .team-wrap:hover .content-part {
  opacity: 1;
}
.rs-team .item .team-wrap:hover:after {
  opacity: 1;
}
/*-----------------------------------------------
    14. Testimonial Section Css
-----------------------------------------------*/
.rs-testimonial.style1 {
  background: #fef8dc;
}
.rs-testimonial.style1 .icon-part {
  position: relative;
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  height: 100%;
}
.rs-testimonial.style1 .icon-part .social-links li {
  position: absolute;
}
.rs-testimonial.style1 .icon-part .social-links li a {
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  background: #ffffff;
  box-shadow: 0 5px 19px 0 rgba(0, 0, 0, 0.08);
}
.rs-testimonial.style1 .icon-part .social-links li a:hover {
  box-shadow: none;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(1) {
  left: 12.5%;
  top: 147px;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(1) a {
  width: 75px;
  height: 75px;
  line-height: 72px;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(2) {
  left: 43.5%;
  top: 83px;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(2) a {
  width: 95px;
  height: 95px;
  line-height: 91px;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(3) {
  right: 21%;
  top: 185px;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(3) a {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(4) {
  right: 16.8%;
  bottom: 151px;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(4) a {
  width: 105px;
  height: 105px;
  line-height: 96px;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(5) {
  bottom: 130px;
  left: 17%;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(5) a {
  width: 85px;
  height: 85px;
  line-height: 79px;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(6) {
  bottom: 226px;
  right: 41.3%;
}
.rs-testimonial.style1 .icon-part .social-links li:nth-child(6) a {
  width: 140px;
  height: 140px;
  line-height: 140px;
}
.rs-testimonial.style1 .testi-slider {
  padding: 0 30px 0 92px;
  overflow: hidden;
  position: relative;
}
.rs-testimonial.style1 .testi-slider > * {
  flex-grow: 1;
}
.rs-testimonial.style1 .testi-slider .circle--rotate {
  border-radius: 50%;
  border: 2px dashed #d6fbff;
  width: 330px;
  height: 330px;
  position: relative;
  transform: rotate(0deg);
  z-index: 0;
  padding: 0;
}
.rs-testimonial.style1 .testi-slider .circle--rotate > li {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  z-index: 10;
  visibility: hidden;
}
.rs-testimonial.style1 .testi-slider .circle--rotate > li .sl-icon {
  visibility: visible;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  font-size: 20px;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  margin: -11px auto;
  transition: all 500ms ease;
}
.rs-testimonial.style1 .testi-slider .circle--rotate > li img {
  margin: auto;
}
.rs-testimonial.style1 .testi-slider .circle--slider {
  position: relative;
  width: 330px;
  padding: 148px 0;
}
.rs-testimonial.style1 .testi-slider .circle--slider .rotate--circle {
  margin: 0 auto;
}
.rs-testimonial.style1 .testi-slider .circle--slider .circle--rotate > li div {
  position: relative;
  overflow: visible;
  background-color: #e8505b;
  border: 4px solid #d6fbff;
  transition: all 0.3s ease 0s;
}
.rs-testimonial.style1 .testi-slider .circle--slider .circle--rotate > li div span {
  transform: rotate(0deg);
  transition: all 2s;
  opacity: 0;
}
.rs-testimonial.style1 .testi-slider .circle--slider .circle--rotate > li div:hover {
  background: #e2b500;
}
.rs-testimonial.style1 .testi-slider .circle--slider .circle--rotate > li.active div {
  background: #e2b500;
}
.rs-testimonial.style1 .testi-slider .circle--slider .count2 li:nth-child(2) {
  transform: rotate(180deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count3 li:nth-child(2) {
  transform: rotate(120deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count3 li:nth-child(3) {
  transform: rotate(240deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count4 li:nth-child(2) {
  transform: rotate(90deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count4 li:nth-child(3) {
  transform: rotate(180deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count4 li:nth-child(4) {
  transform: rotate(270deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count5 li:nth-child(2) {
  transform: rotate(72deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count5 li:nth-child(3) {
  transform: rotate(144deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count5 li:nth-child(4) {
  transform: rotate(216deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count5 li:nth-child(5) {
  transform: rotate(-72deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count6 li:nth-child(2) {
  transform: rotate(60deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count6 li:nth-child(3) {
  transform: rotate(120deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count6 li:nth-child(4) {
  transform: rotate(180deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count6 li:nth-child(5) {
  transform: rotate(240deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count6 li:nth-child(6) {
  transform: rotate(-60deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count7 li:nth-child(2) {
  transform: rotate(51.5deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count7 li:nth-child(3) {
  transform: rotate(103deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count7 li:nth-child(4) {
  transform: rotate(154.5deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count7 li:nth-child(5) {
  transform: rotate(206deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count7 li:nth-child(6) {
  transform: rotate(-103deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count7 li:nth-child(7) {
  transform: rotate(-51.5deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count8 li:nth-child(2) {
  transform: rotate(45deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count8 li:nth-child(3) {
  transform: rotate(90deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count8 li:nth-child(4) {
  transform: rotate(135deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count8 li:nth-child(5) {
  transform: rotate(180deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count8 li:nth-child(6) {
  transform: rotate(-135deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count8 li:nth-child(7) {
  transform: rotate(-90deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count8 li:nth-child(8) {
  transform: rotate(-45deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count9 li:nth-child(2) {
  transform: rotate(40deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count9 li:nth-child(3) {
  transform: rotate(80deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count9 li:nth-child(4) {
  transform: rotate(120deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count9 li:nth-child(5) {
  transform: rotate(160deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count9 li:nth-child(6) {
  transform: rotate(-160deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count9 li:nth-child(7) {
  transform: rotate(-120deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count9 li:nth-child(8) {
  transform: rotate(-80deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count9 li:nth-child(9) {
  transform: rotate(-40deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count10 li:nth-child(2) {
  transform: rotate(36deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count10 li:nth-child(3) {
  transform: rotate(72deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count10 li:nth-child(4) {
  transform: rotate(108deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count10 li:nth-child(5) {
  transform: rotate(144deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count10 li:nth-child(6) {
  transform: rotate(-180deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count10 li:nth-child(7) {
  transform: rotate(-144deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count10 li:nth-child(8) {
  transform: rotate(-108deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count10 li:nth-child(9) {
  transform: rotate(-72deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .count10 li:nth-child(10) {
  transform: rotate(-36deg);
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate-wrapper {
  width: 256px;
  height: 256px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate-img .animate-img__in {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  background-repeat: no-repeat;
  z-index: 1;
  transform: rotate3d(0, 0, 0, 0deg);
  transition: 0.55s ease-in-out;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-size: cover;
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate-more {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s 0.4s;
  max-width: 500px;
  z-index: 30;
  position: absolute;
  top: 0;
  right: -230%;
  bottom: 0;
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate-more .animate-content {
  color: #ffffff;
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate-more .animate-content .icon-part {
  padding-top: 10px;
  margin-bottom: 17px;
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate-more .animate-content .icon-part i {
  color: #ffffff;
  opacity: 0.8;
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate-more .animate-content .icon-part i:before {
  font-size: 80px;
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate-more .animate-content .desc {
  margin-bottom: 18px;
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate-more .animate-content .title {
  color: #ffffff;
  margin-bottom: 2px;
  line-height: 1;
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate-more .animate-content .designation {
  color: #f1f1f1;
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate.active .animate-more {
  opacity: 1;
  visibility: visible;
}
.rs-testimonial.style1 .testi-slider .circle--slider .animate.active .animate-img .animate-img__in {
  opacity: 1;
}
.rs-testimonial.style1 .testi-slider .circle--slider .prev,
.rs-testimonial.style1 .testi-slider .circle--slider .next {
  position: absolute;
  bottom: 0;
  left: -92px;
  display: block;
  height: 60px;
  line-height: 60px;
  width: 60px;
  text-align: center;
  cursor: pointer;
  background: #e8505b;
  color: #ffffff;
  transition: all 0.3s ease 0s;
}
.rs-testimonial.style1 .testi-slider .circle--slider .prev:hover,
.rs-testimonial.style1 .testi-slider .circle--slider .next:hover {
  background: #ffffff;
  color: #e8505b;
}
.rs-testimonial.style1 .testi-slider .circle--slider .prev {
  left: -32px;
}
.rs-testimonial.style1 .testi-slider .line {
  position: absolute;
  left: -1px;
  bottom: 101px;
}
.rs-testimonial.style1 .testi-slider .circle-style {
  position: absolute;
  right: -74px;
  bottom: 20px;
  width: 147px;
  height: 147px;
  border-radius: 100%;
}
.rs-testimonial.style1 .testi-slider .circle-style:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: #d6fbff;
}
.rs-testimonial.style1 .testi-slider .circle-style:before {
  position: absolute;
  content: "";
  right: 20px;
  bottom: 20px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  animation: spine 30s linear infinite;
  border: 1px dashed #d6fbff;
}
.rs-testimonial.style1 .testi-shape {
  position: absolute;
  left: 0;
  top: 0;
  width: 131px;
  height: 100%;
}
/*-----------------------------------------------
    15. Blog Section Css
-----------------------------------------------*/
.rs-blog.style1 .view-btn a {
  font-weight: 600;
  display: inline-block;
  color: #3b4052;
}
.rs-blog.style1 .view-btn a i {
  display: inline-block;
  text-align: center;
  font-weight: normal;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #e8505b;
  color: #ffffff;
  transition: all 0.3s ease 0s;
}
.rs-blog.style1 .view-btn a i:before {
  font-size: 15px;
}
.rs-blog.style1 .view-btn a:hover i {
  background: #e2b500;
}
.rs-blog.style1 .item .blog-wrap {
  position: relative;
}
.rs-blog.style1 .item .blog-wrap .content-wrap {
  position: absolute;
  width: 100%;
  padding: 0 53px 0 50px;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.rs-blog.style1 .item .blog-wrap .content-wrap .date-meta {
  color: #3b4052;
  transition: all 0.3s ease 0s;
  margin-bottom: 13px;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .date-meta .day {
  font-size: 24px;
  font-weight: 700;
  line-height: 15px;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .date-meta .month {
  font-weight: 500;
  font-size: 14px;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .category {
  margin-bottom: 12px;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .category li {
  display: inline;
  position: relative;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .category li a {
  color: #555555;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .category li a:hover {
  color: #e2b500;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .category li:after {
  content: ",";
  transition: all 0.3s ease 0s;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .category li:last-child:after {
  display: none;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .title {
  margin-bottom: 22px;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .title a {
  color: #3b4052;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .title a:hover {
  color: #e2b500;
}
.rs-blog.style1 .item .blog-wrap .content-wrap .desc {
  margin-bottom: 21px;
  transition: all 0.3s ease 0s;
}
.rs-blog.style1 .item .blog-wrap:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f5feff;
  left: 0;
  top: 0;
  transition: all 0.3s ease 0s;
}
.rs-blog.style1 .item .blog-wrap:hover .content-wrap .date-meta {
  color: #ffffff;
}
.rs-blog.style1 .item .blog-wrap:hover .content-wrap .category li a {
  color: #ffffff;
}
.rs-blog.style1 .item .blog-wrap:hover .content-wrap .category li a:hover {
  color: #e2b500;
}
.rs-blog.style1 .item .blog-wrap:hover .content-wrap .category li:after {
  color: #ffffff;
}
.rs-blog.style1 .item .blog-wrap:hover .content-wrap .title a {
  color: #ffffff;
}
.rs-blog.style1 .item .blog-wrap:hover .content-wrap .title a:hover {
  color: #e2b500;
}
.rs-blog.style1 .item .blog-wrap:hover .content-wrap .desc {
  color: #ffffff;
}
.rs-blog.style1 .item .blog-wrap:hover .content-wrap .read-btn .readon2 {
  color: #ffffff;
}
.rs-blog.style1 .item .blog-wrap:hover .content-wrap .read-btn .readon2:after {
  color: #ffffff;
}
.rs-blog.style1 .item .blog-wrap:hover .content-wrap .read-btn .readon2:hover {
  color: #e2b500;
}
.rs-blog.style1 .item .blog-wrap:hover .content-wrap .read-btn .readon2:hover:after {
  color: #e2b500;
}
.rs-blog.style1 .item .blog-wrap:hover:after {
  background: rgba(0, 0, 0, 0.8);
}
.rs-blog.style2 .blog-wrap .top-part {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.rs-blog.style2 .blog-wrap .top-part img {
  width: 100%;
}
.rs-blog.style2 .blog-wrap .top-part .postby {
  position: absolute;
  top: 20px;
  left: 35px;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: all 0.3s ease 0s;
  opacity: 0;
}
.rs-blog.style2 .blog-wrap .top-part .postby .avatar {
  margin-right: 15px;
}
.rs-blog.style2 .blog-wrap .top-part .postby .info .name {
  margin: 0 0 -5px;
}
.rs-blog.style2 .blog-wrap .top-part .postby .info .name a {
  color: #ffffff;
}
.rs-blog.style2 .blog-wrap .top-part .postby .info .name a:hover {
  color: #e2b500;
}
.rs-blog.style2 .blog-wrap .top-part .postby .info .designation {
  font-weight: 300;
  color: #ffffff;
}
.rs-blog.style2 .blog-wrap .top-part:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.3s ease 0s;
}
.rs-blog.style2 .blog-wrap .content-part {
  padding-top: 23px;
}
.rs-blog.style2 .blog-wrap .content-part .info-meta li {
  display: inline-block;
  margin-right: 12px;
  padding-right: 21px;
  font-weight: 300;
  color: #555;
  position: relative;
}
.rs-blog.style2 .blog-wrap .content-part .info-meta li a {
  color: #555;
}
.rs-blog.style2 .blog-wrap .content-part .info-meta li a:hover {
  color: #e2b500;
}
.rs-blog.style2 .blog-wrap .content-part .info-meta li:after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  background: #d6fbff;
  border-radius: 100%;
}
.rs-blog.style2 .blog-wrap .content-part .info-meta li:last-child {
  margin: 0;
  padding: 0;
}
.rs-blog.style2 .blog-wrap .content-part .info-meta li:last-child:after {
  position: unset;
  content: unset;
}
.rs-blog.style2 .blog-wrap .content-part .title {
  margin: 20px 0 16px;
}
.rs-blog.style2 .blog-wrap .content-part .title a {
  color: #003b40;
}
.rs-blog.style2 .blog-wrap .content-part .title a:hover {
  color: #e2b500;
}
.rs-blog.style2 .blog-wrap:hover .top-part .postby {
  opacity: 1;
  top: 30px;
}
.rs-blog.style2 .blog-wrap:hover .top-part:after {
  opacity: 1;
}
.rs-blog.blog-details .blog-content .categories li {
  display: inline;
}
.rs-blog.blog-details .blog-content .categories li a {
  font-weight: 300;
  color: #555555;
}
.rs-blog.blog-details .blog-content .categories li a:hover {
  color: #e2b500;
}
.rs-blog.blog-details .blog-content .categories li:after {
  content: ",";
}
.rs-blog.blog-details .blog-content .categories li:last-child:after {
  content: unset;
}
.rs-blog.blog-details .blog-content .quote-blog {
  color: #222222;
  font-size: 17px;
  border-left: 5px solid #d4d4d4;
  padding-left: 28px;
}
.rs-blog.blog-details .blog-content .tags span {
  margin-right: 20px;
}
.rs-blog.blog-details .blog-content .tags a {
  display: inline-block;
  background: #ededed;
  border-radius: 3px;
  padding: 2px 20px;
  font-weight: 500;
  color: #3b4052;
  margin-right: 10px;
}
.rs-blog.blog-details .blog-content .tags a:hover {
  background: #e2b500;
}
.rs-blog.blog-details .blog-content .post-author {
  display: flex;
  align-items: center;
  background: #f3f3f3;
  border-radius: 5px;
  padding: 42px 45px 40px;
}
.rs-blog.blog-details .blog-content .post-author .avatar {
  margin-right: 20px;
}
.rs-blog.blog-details .blog-content .post-author .info .name {
  margin: 0;
  color: #3b4052;
  transition: all 0.3s ease 0s;
}
.rs-blog.blog-details .blog-content .post-author .info .designation {
  font-weight: 300;
  color: #555555;
  font-size: 14px;
}
.rs-blog.blog-details .blog-content .post-author:hover .info .name {
  color: #e2b500;
}
.rs-blog.blog-details .blog-content .post-nav {
  display: flex;
  padding: 28px 40px;
  border-radius: 5px;
  border: 2px solid #eeeeee;
}
.rs-blog.blog-details .blog-content .post-nav .controller {
  padding-top: 26px;
  padding-bottom: 22px;
  border-right: 1px solid #eee;
}
.rs-blog.blog-details .blog-content .post-nav .controller .title {
  margin-bottom: 5px;
}
.rs-blog.blog-details .blog-content .post-nav .controller .title a {
  color: #3b4052;
}
.rs-blog.blog-details .blog-content .post-nav .controller .title a:hover {
  color: #e2b500;
}
.rs-blog.blog-details .blog-content .post-nav .controller .post-nav-link {
  color: #555555;
  font-weight: 600;
}
.rs-blog.blog-details .blog-content .post-nav .controller .post-nav-link i {
  font-weight: normal;
  position: relative;
  top: 2px;
}
.rs-blog.blog-details .blog-content .post-nav .controller .post-nav-link:hover {
  color: #e2b500;
}
.rs-blog.blog-details .blog-content .post-nav .controller.prev-post {
  padding-right: 20px;
}
.rs-blog.blog-details .blog-content .post-nav .controller.prev-post i {
  margin-right: 10px;
}
.rs-blog.blog-details .blog-content .post-nav .controller.next-post {
  text-align: right;
  padding-left: 20px;
  border: none;
}
.rs-blog.blog-details .blog-content .post-nav .controller.next-post i {
  margin-left: 10px;
}
.rs-blog.blog-details .blog-content .recent-comments-area h3 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 42px 0 6px;
}
.rs-blog.blog-details .blog-content .recent-comments-area span {
  font-size: 13px;
  font-weight: 500;
}
.rs-blog.blog-details .blog-content .recent-comments-area span a {
  font-size: 13px;
  font-weight: 500;
}
.rs-blog.blog-details .blog-content .recent-comments-area span i {
  font-size: 13px;
}
.rs-blog.blog-details .blog-content .recent-comments-area h4 {
  font-size: 15px;
  font-weight: 700;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul {
  padding: 0;
  margin: 0;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li {
  margin: 0 0 2px;
  padding: 20px 22px;
  border-bottom: 1px solid #ddd;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li:nth-child(2) {
  margin-left: 100px;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li:nth-child(2) .col-sm-2 {
  padding: 0;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li:last-child {
  border-bottom: none;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li .image-comments {
  margin-top: 0;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li .image-comments img {
  width: 90px;
  border-radius: 100%;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li .reply {
  display: block;
  position: absolute;
  text-align: right;
  width: 95%;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li .reply a {
  color: #e2b500;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li .reply a:hover {
  color: #458dff;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li .reply i {
  color: #e2b500;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li .dsc-comments .reply-btn {
  border: 1px solid #e2b500;
  color: #e2b500;
  display: inline-block;
  padding: 5px 25px;
  border-radius: 30px;
  transition: all 0.3s ease 0s;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li .dsc-comments .reply-btn:hover {
  color: #ffffff;
  background: #e2b500;
  cursor: pointer;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li .dsc-comments h4 {
  margin: 0 0 10px;
}
.rs-blog.blog-details .blog-content .recent-comments-area ul li .dsc-comments p {
  margin: 5px 0;
}
.rs-blog.blog-details .blog-content .leave-comments-area {
  padding-top: 20px;
}
.rs-blog.blog-details .blog-content .leave-comments-area .form-group {
  margin-bottom: 20px;
}
.rs-blog.blog-details .blog-content .leave-comments-area h3.title {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Cairo", sans-serif;
}
.rs-blog.blog-details .blog-content .leave-comments-area label {
  color: ;
  font-weight: normal;
  letter-spacing: 1px;
}
.rs-blog.blog-details .blog-content .leave-comments-area input {
  background: transparent;
  border: 1px solid #d7d7d7;
  box-shadow: none;
  border-radius: 0;
  height: 45px;
  width: 100%;
}
.rs-blog.blog-details .blog-content .leave-comments-area textarea {
  background: transparent;
  border: 1px solid #d7d7d7;
  box-shadow: none;
  border-radius: 0;
  height: 45px;
  height: 150px;
  width: 100%;
  max-width: 100%;
}
.rs-blog.blog-details .blog-content .leave-comments-area .btn-send {
  outline: none;
  border: 2px solid #e2b500;
  line-height: 47px;
}
.rs-blog.blog-details .blog-content .leave-comments-area .btn-send:hover {
  background: transparent;
  color: #e2b500;
}
.rs-blog .sidebar .common {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.07);
  padding: 40px 47px;
  border-radius: 4px;
}
.rs-blog .sidebar .widget-title {
  color: #003b40;
}
.rs-blog .sidebar .search-wrap form {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.rs-blog .sidebar .search-wrap form [type="search"] {
  border: 1px solid #ececec;
  color: #444444;
  padding: 14px 20px;
  width: 100%;
  font-weight: 300;
  outline: none;
}
.rs-blog .sidebar .search-wrap form ::-webkit-input-placeholder {
  color: #444;
}
.rs-blog .sidebar .search-wrap form ::-ms-input-placeholder {
  color: #444;
}
.rs-blog .sidebar .search-wrap form ::placeholder {
  color: #444;
}
.rs-blog .sidebar .search-wrap form button {
  background: transparent;
  border: medium none;
  color: #3b4052;
  position: absolute;
  display: block;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  padding: 0 20px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  outline: none;
}
.rs-blog .sidebar .search-wrap form button:hover {
  background: #e2b500;
  color: #ffffff;
}
.rs-blog .sidebar .recent-post .recent-post-widget {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.rs-blog .sidebar .recent-post .recent-post-widget .post-img {
  width: 100px;
}
.rs-blog .sidebar .recent-post .recent-post-widget .post-img img {
  width: 100%;
  border-radius: 3px;
}
.rs-blog .sidebar .recent-post .recent-post-widget .post-desc {
  padding-left: 25px;
}
.rs-blog .sidebar .recent-post .recent-post-widget .post-desc a {
  color: #003b40;
  font-weight: 500;
}
.rs-blog .sidebar .recent-post .recent-post-widget .post-desc a:hover {
  color: #e2b500;
}
.rs-blog .sidebar .recent-post .recent-post-widget .post-desc .date {
  font-size: 14px;
  color: #555555;
  font-weight: 300;
  display: block;
}
.rs-blog .sidebar .recent-post .recent-post-widget .post-desc .date i {
  font-size: 12px;
  line-height: 12px;
  color: #555555;
  font-weight: 400;
}
.rs-blog .sidebar .recent-post .recent-post-widget:last-child {
  margin: 0;
  padding: 0;
  border: none;
}
.rs-blog .sidebar .categories li {
  margin-bottom: 8px;
}
.rs-blog .sidebar .categories li a {
  position: relative;
  color: #003b40;
  font-weight: 500;
  padding-right: 30px;
}
.rs-blog .sidebar .categories li a:after {
  font-family: Flaticon;
  content: "\f120";
  position: absolute;
  top: 50%;
  font-size: 15px;
  transform: translateY(-50%);
  right: 0;
}
.rs-blog .sidebar .categories li a:hover {
  color: #e2b500;
  padding-right: 35px;
}
.rs-blog .sidebar .categories li:last-child {
  margin: 0;
}
/*-----------------------------------------------
    16. Call to Action Section Css
-----------------------------------------------*/
.rs-cta .cta-wrap {
  width: 495px;
  height: 495px;
  margin: 0 auto;
  border-radius: 100%;
  position: relative;
  text-align: center;
  box-shadow: inset 0 0 50px 0px rgba(0, 0, 0, 0.1);
}
.rs-cta .cta-wrap .content-part {
  position: absolute;
  width: 100%;
  content: "";
  left: 50%;
  top: 50%;
  padding: 0 50px;
  transform: translate(-50%, -50%);
}
.rs-cta .cta-wrap .content-part .btn-part a {
  display: inline-block;
  padding: 11px 30px;
  border: 2px solid #ffffff;
  border-radius: 30px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
}
.rs-cta .cta-wrap .content-part .btn-part a:hover {
  background: #ffffff;
  color: #e8505b;
}
.rs-cta .cta-wrap .inner-spiner {
  animation: spine2 20s linear infinite;
  -webkit-animation: spine2 20s linear infinite;
  position: absolute;
  top: 60px;
  right: 120px;
  width: 70px;
  height: 70px;
  pointer-events: none;
}
.rs-cta .cta-wrap .inner-spiner div {
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background: #e22331;
  border-radius: 100%;
  position: absolute;
}
.rs-cta .cta-wrap .inner-spiner div:nth-child(2) {
  left: 0;
  top: unset;
  bottom: 0;
}
.rs-cta .cta-wrap .inner-spiner div:nth-child(3) {
  left: unset;
  right: 5px;
  top: 25px;
}
.rs-cta .cta-wrap .outer-spiner {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  pointer-events: none;
}
.rs-cta .cta-wrap .outer-spiner .motor div {
  border-radius: 100%;
  position: absolute;
  animation: scale 1s alternate infinite;
  -webkit-animation: scale 1s alternate infinite;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(1) {
  background: #1835a9;
  width: 53px;
  height: 53px;
  left: -105px;
  top: 194px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(2) {
  border: 1px dashed #1835a9;
  width: 53px;
  height: 53px;
  left: -116px;
  top: 188px;
  animation: spine 15s linear infinite;
  -webkit-animation: spine 15s linear infinite;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(3) {
  background: #e2b500;
  width: 30px;
  height: 30px;
  left: -35px;
  top: 154px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(4) {
  background: #e2b500;
  width: 11px;
  height: 11px;
  left: 0;
  top: 40px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(5) {
  background: #e2b500;
  width: 11px;
  height: 11px;
  left: 185px;
  top: -41px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(6) {
  background: #e8505b;
  width: 21px;
  height: 21px;
  right: 86px;
  top: -21px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(7) {
  background: #48a9c5;
  width: 53px;
  height: 53px;
  right: -60px;
  top: 93px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(8) {
  background: #ffecd7;
  width: 53px;
  height: 53px;
  right: -83px;
  top: 275px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(9) {
  background: #e8505b;
  width: 16px;
  height: 16px;
  right: -50px;
  bottom: 82px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(10) {
  background: #e2b500;
  width: 16px;
  height: 16px;
  right: 50px;
  bottom: 0;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(11) {
  background: #fff2e3;
  opacity: 0.7;
  width: 82px;
  height: 82px;
  right: 49px;
  bottom: -9px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(12) {
  background: #e8505b;
  width: 21px;
  height: 21px;
  right: 222px;
  bottom: -46px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(13) {
  background: #cef5f9;
  width: 21px;
  height: 21px;
  left: 41px;
  bottom: -8px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(14) {
  background: #ffdeb8;
  width: 53px;
  height: 53px;
  left: -63px;
  bottom: 73px;
}
.rs-cta .cta-wrap .outer-spiner .motor div:nth-child(15) {
  background: #48a9c5;
  width: 53px;
  height: 53px;
  left: -84px;
  bottom: 73px;
}
.rs-cta .cta-wrap:after {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  left: 20px;
  border-radius: 100%;
  bottom: 10px;
  border: 2px dashed #ee7d85;
  animation: spine 50s linear infinite;
  -webkit-animation: spine 50s linear infinite;
  z-index: -1;
}
/*-----------------------------------------------
   17. Conatct Section Css
-----------------------------------------------*/
.rs-contact .contact-info .info-wrap {
  display: flex;
  padding: 70px 54px 61px;
  box-shadow: -1px 7px 20px 3px rgba(0, 0, 0, 0.04);
}
.rs-contact .contact-info .info-wrap img {
  height: 50px;
  width: auto;
}
.rs-contact .contact-info .info-wrap .content-part {
  padding-left: 25px;
}
.rs-contact .contact-info .info-wrap .content-part .title {
  font-size: 18px;
  margin-bottom: 3px;
}
.rs-contact .contact-info .info-wrap .content-part .desc {
  font-weight: 300;
}
.rs-contact .contact-info .info-wrap .content-part .desc a {
  color: #555555;
  display: block;
}
.rs-contact .contact-info .info-wrap .content-part .desc a:hover {
  color: #e2b500;
}
.rs-contact .contact-form .img-part {
  position: absolute;
  left: 60px;
  bottom: 0;
  z-index: 1;
}
.rs-contact .contact-form form.form-here {
  border-radius: 4px;
  box-shadow: 0 5px 9px 4px rgba(0, 0, 0, 0.02);
  padding: 80px 60px;
  background: #ffffff;
  max-width: 600px;
  margin: 0 0 0 auto;
}
.rs-contact .contact-form form.form-here input,
.rs-contact .contact-form form.form-here textarea,
.rs-contact .contact-form form.form-here button {
  outline: none;
}
.rs-contact .contact-form form.form-here input,
.rs-contact .contact-form form.form-here textarea {
  border: 1px solid #ececec;
  border-radius: 4px;
  background: #fafafa;
  width: 100%;
  padding: 15px 30px;
  margin-bottom: 30px;
}
.rs-contact .contact-form form.form-here input {
  height: 62px;
}
.rs-contact .contact-form form.form-here textarea {
  height: 193px;
  float: left;
}
.rs-contact .contact-form form.form-here button {
  border: none;
  border-radius: 3px;
  background: #458dff;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  padding: 14px 39px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
.rs-contact .contact-form form.form-here button:hover {
  background: #e2b500;
}
.rs-contact .contact-form form.form-here ::placeholder {
  color: #666666;
}
.rs-contact .contact-form form.form-here ::-webkit-placeholder {
  color: #666666;
}
.rs-contact .contact-form form.form-here ::-ms-placeholder {
  color: #666666;
}
.rs-contact .contact-form .container:after,
.rs-contact .contact-form .container:before {
  position: absolute;
  content: "";
  width: 34px;
  height: 34px;
  border-radius: 100%;
  border: 4px solid #e2b500;
  background: #ffffff;
}
.rs-contact .contact-form .container:after {
  left: -61px;
  top: 10px;
  animation: move-y2 7s alternate infinite;
  -webkit-animation: move-y2 7s alternate infinite;
}
.rs-contact .contact-form .container:before {
  right: -100px;
  bottom: -90px;
  animation: move-x2 7s alternate infinite;
  -webkit-animation: move-x2 7s alternate infinite;
}
.rs-contact .map-area iframe {
  border: none;
  display: inherit;
  width: 100%;
  height: 700px;
}
/*-----------------------------------------------
    18. Footer Section Css
-----------------------------------------------*/
.rs-footer {
  background: url(src/images/background/footer-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.rs-footer .footer-content .about-widget {
  position: relative;
}
.rs-footer .footer-content .about-widget .footer-desc {
  color: #3b4052;
  margin-bottom: 24px;
}
.rs-footer .footer-content .about-widget:after {
  position: absolute;
  content: "";
  width: 32px;
  height: 32px;
  left: -63px;
  top: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 6px solid #e2b500;
  animation: y-anim2 5s alternate infinite;
}
.rs-footer .footer-content .widget .widget-title-wrap .widget-title {
  margin-bottom: 0;
}
.rs-footer .footer-content .widget .sitemap li {
  margin-bottom: 18px;
}
.rs-footer .footer-content .widget .sitemap li a {
  color: #3b4052;
  display: inline-block;
  font-weight: 600;
}
.rs-footer .footer-content .widget .sitemap li a:hover {
  color: #458dff;
  margin-left: 5px;
}
.rs-footer .footer-content .widget .sitemap li:last-child {
  margin: 0;
}
.rs-footer .footer-bottom {
  padding: 30px 0;
}
.rs-footer .footer-bottom .footer-menu li {
  display: inline;
  margin-right: 44px;
}
.rs-footer .footer-bottom .footer-menu li a {
  display: inline-block;
  font-weight: 500;
  color: #3b4052;
  text-transform: uppercase;
}
.rs-footer .footer-bottom .footer-menu li a:hover {
  color: #458dff;
}
.rs-footer .footer-bottom .footer-menu li:last-child {
  margin: 0;
}
.rs-footer .footer-bottom .copyright li {
  display: inline;
  margin-right: 44px;
}
.rs-footer .footer-bottom .copyright li a {
  display: inline-block;
  font-weight: 300;
  color: #3b4052;
}
.rs-footer .footer-bottom .copyright li a:hover {
  color: #458dff;
}
.rs-footer .footer-bottom .copyright li:last-child {
  margin: 0;
}
.rs-footer .footer-bottom .social-links li {
  display: inline;
  margin-right: 20px;
}
.rs-footer .footer-bottom .social-links li a {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 47px;
  border-radius: 100%;
  display: inline-block;
  background: #ffffff;
  font-size: 16px;
  color: #e2b500;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}
.rs-footer .footer-bottom .social-links li a:hover {
  background: #458dff;
  color: #ffffff;
}
.rs-footer .footer-bottom .social-links li:last-child {
  margin: 0;
}
/*-----------------------------------------------
    19. Search Modal Css
-----------------------------------------------*/
.search-modal {
  padding: 0 !important;
}
.search-modal .modal-content {
  background: transparent;
  position: initial;
  border: 0;
}
.search-modal .modal-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
}
.search-modal .search-block input {
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
  border-color: #ffffff;
  box-shadow: none;
  color: #ffffff;
  font-size: 40px;
  font-family: "Cairo", sans-serif;
}
.search-modal .search-block ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
  opacity: 1;
}
.search-modal .search-block ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
  opacity: 1;
}
.search-modal .search-block :-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
  opacity: 1;
}
.search-modal .search-block :-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
  opacity: 1;
}
.search-modal .close {
  color: #3b4052;
  margin-top: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  height: 45px;
  width: 45px;
  line-height: 30px !important;
  text-align: center;
  border-radius: 50%;
  opacity: 1;
  outline: none;
  transition: all 0.3s ease 0s !important;
  transform: rotate(0deg) !important;
}
.search-modal .close:hover {
  transform: rotate(90deg) !important;
}
.modal-backdrop {
  background-color: #458dff;
}
.modal-backdrop.show {
  opacity: 0.9;
  z-index: 99;
}
/*-----------------------------------------------
    20. Header Cart Modal Css
-----------------------------------------------*/
.woocommerce-mini-cart {
  position: absolute;
  padding: 30px 20px;
  background: #fff;
  right: 0;
  top: 200%;
  transition: all 0.3s ease 0s;
  width: 270px;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}
.woocommerce-mini-cart li {
  border-bottom: 1px solid rgba(170, 170, 170, 0.25);
  padding: 0 0 20px 0 !important;
  margin: 0 0 20px 0 !important;
  margin-right: 0 !important;
  border: none !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.woocommerce-mini-cart li:last-child {
  margin: 0 !important;
}
.woocommerce-mini-cart .product-info {
  padding: 0 26px;
}
.woocommerce-mini-cart .product-info a {
  color: #3b4052 !important;
}
.woocommerce-mini-cart .product-info a:hover {
  color: #458dff !important;
}
.woocommerce-mini-cart .total-price {
  padding: 0 0 20px;
}
.woocommerce-mini-cart .icon-cart i {
  color: #555555;
  width: 18px;
  height: 18px;
  line-height: 16px;
  border: 1px solid #555555;
  border-radius: 30px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  transition: all 0.3s ease 0s;
}
.woocommerce-mini-cart .icon-cart i:hover {
  color: #458dff;
  border-color: #458dff;
}
.woocommerce-mini-cart .icon-cart,
.woocommerce-mini-cart .product-image {
  margin-top: 2px;
}
.woocommerce-mini-cart .product-image {
  display: block;
  float: right;
  text-align: right;
  width: 56px;
}
.woocommerce-mini-cart .crt-btn {
  padding: 5px 10px;
  background: transparent;
  color: #3b4052;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #458dff;
}
.woocommerce-mini-cart .crt-btn:hover {
  background: #458dff;
  color: #ffffff;
}
.woocommerce-mini-cart .crt-btn.btn1 {
  margin-right: 10px;
}
.mini-cart-active:hover .woocommerce-mini-cart {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
/* ------------------------------------
    21. Preloader CSS
---------------------------------------*/
#tekhub-load {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  margin-top: 0;
  top: 0;
  background: #fff;
}
.loader-new {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#loader-1:after,
#loader-1:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 6px solid transparent;
  border-top-color: #e2b500;
}
#loader-1:before {
  z-index: 100;
  animation: spin 1.5s infinite;
}
#loader-1:after {
  border: 6px solid #458dff;
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner {
  width: 40px;
  height: 40px;
  margin: -19px 0 0 -21px;
  background-color: #e2b500;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
